import type { DropFile } from '@pesto/features';
import { LoadingSpinner } from '@pesto/ui/components/LoadingSpinner';
import { Cross1Icon } from '@radix-ui/react-icons';

interface FilePreviewProps {
  file: DropFile;
  onDelete: (file: File) => void;
  isLoading: boolean;
}

export const ImageUploaderPreview = (props: FilePreviewProps) => {
  const { file, onDelete, isLoading } = props;

  const handleDelete = () => {
    if (isLoading) return;
    return onDelete(file);
  };

  return (
    <div key={file.name} className={'relative z-[99]'}>
      {isLoading && (
        <div className={'absolute inset-0 flex items-center justify-center bg-black bg-opacity-50'}>
          <LoadingSpinner />
        </div>
      )}

      <div>
        <img
          className={'h-[64px] w-[64px] rounded object-cover'}
          src={file.preview}
          onLoad={() => {
            URL.revokeObjectURL(file.preview || '');
          }}
          alt={`Preview of ${file.name}`}
        />
      </div>
      <div
        className={'absolute right-0 top-0 -translate-y-4 translate-x-4 p-2 hover:cursor-pointer'}
        onClick={handleDelete}
      >
        <Cross1Icon className={'h-5 w-5'} />
      </div>
    </div>
  );
};
