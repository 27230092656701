import { TextField } from '@pesto/ui/components/Forms/TextField';
import type { ChangeEvent } from 'react';

interface Props {
  className?: string;
  onChange: (event: {
    target: {
      value: number;
    };
  }) => void;
  value: number;
  label: string;
  required?: boolean;
  error?: boolean;
  helperText?: string;
  dollarsOnly?: boolean;
  toolTipText?: string;
  allowZeroInput?: boolean;
}

export const CurrencyFormatField = (props: Props) => {
  const formatCurrency = (value: number) => {
    if (!value && !(value === 0 && props.allowZeroInput)) return '';

    const formatted = (Number(value) / 100).toLocaleString('en-US', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return props.dollarsOnly ? formatted.slice(0, -3) : formatted;
  };

  const onChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (event.target.value === '-') {
      props.onChange({ target: { value: 0 } });
      return;
    }

    const numberValue = parseInt(event.target.value.replace(/[^-?\d]/g, ''));
    const newValue = props.dollarsOnly ? numberValue * 100 : numberValue;

    // TODO: the requirement to pass an event up to props.onChange should be removed.
    // Instead we should either update a form context, or pass back only the correctly formatted value on change
    props.onChange({ target: { value: newValue } });
  };
  return (
    <TextField
      className={props.className}
      value={formatCurrency(props.value)}
      onChange={onChange}
      label={props.label}
      required={props.required}
      error={props.error ? 'true' : undefined}
    />
  );
};
