import type { DropdownOption } from '@pesto/ui/components/DropdownInput';
import { Modal } from '@pesto/ui/components/Modal';
import { CreditApplicationDetailScreen } from '@pesto/ui/features/CreditApplication/CreditApplicationDetailScreen';
import { CreditCheckContent } from '@pesto/ui/features/CreditApplication/components/CreditCheckContent';
import { OverviewDetails } from '@pesto/ui/features/CreditApplication/components/OverviewDetails';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { AssetType, useGetCreditApplicationByIdQuery } from '../../__generated__/graphql/api';
import { UserDetails } from '../../components/UserDetails/UserDetails';
import { routes } from '../../constants/routes';

import { AdminActionRecordsContent } from './components/AdminActionRecordsContent';
import { ApplicationDetailsView } from './components/ApplicationDetailsView';
import { DocCheckContent } from './components/DocCheckContent';
import { ShippingDetailsContent } from './components/ShippingDetailsContent';
import { useApplicationData } from './hooks/useApplicationData';
import { ApplicationActions, useApplicationModal } from './hooks/useApplicationModal';
import { useCreditApplicationDetails } from './hooks/useCreditApplicationDetails';

export const addBasicItemsOptions: DropdownOption[] = [
  { value: AssetType.Jewelry, label: 'Jewelry' },
  { value: AssetType.PreciousMetals, label: 'Precious Metal' },
  { value: AssetType.Gemstones, label: 'Gemstones' },
  { value: AssetType.Other, label: 'Other' },
];

export enum DrawerContent {
  PERSON = 'PERSON',
  CREDIT_CHECK = 'CREDIT_CHECK',
  DOC_CHECK = 'DOC_CHECK',
  ADMIN_ACTION_RECORDS = 'ADMIN_ACTION_RECORDS',
}

export const CreditApplicationDetail = () => {
  const [showModal, setShowModal] = useState(false);

  const params = useParams();
  const { data, loading, refetch } = useGetCreditApplicationByIdQuery({
    variables: { creditApplicationId: params?.id ?? '' },
    fetchPolicy: 'cache-and-network',
  });

  const [filterInactive, setFilterInactive] = useState(true);

  const handleSetFilterInactive = () => {
    setFilterInactive(!filterInactive);
  };
  const application = data?.creditApplication;

  const items = filterInactive ? application?.items?.filter(item => item?.active === true) : application?.items;
  const inNoActiveItems = application?.items?.filter(item => item?.active === false);

  const { setAction, actionLoading, modalContent, getModalTitle, handleModalConfirm, setDocCheckId } =
    useApplicationModal({
      application,
    });

  const handleButtonClick = (docCheckId: string) => {
    setDocCheckId(docCheckId);
    setAction(ApplicationActions.REVIEW_DOC_CHECK);
    setShowModal(true);
  };

  const { mainActionButtonsRender } = useCreditApplicationDetails();
  const { getApplicationData } = useApplicationData({ application, setShowModal, setAction });

  return (
    <div>
      <CreditApplicationDetailScreen
        breadcrumb={{
          items: [
            { href: routes.dashboard, label: 'Dashboard' },
            { href: routes.creditApplications, label: 'Credit Applications' },
            { label: `${application?.id}` },
          ],
        }}
        tabs={[
          {
            label: 'Application view',
            value: 'application',
            content: (
              <div className="flex flex-col gap-4">
                <OverviewDetails
                  actionButtons={mainActionButtonsRender(application?.status)}
                  applicationData={getApplicationData()}
                  onActionButtonClick={action => {
                    setAction(action);
                    setShowModal(true);
                  }}
                />
                <ApplicationDetailsView
                  items={items}
                  inNoActiveItems={inNoActiveItems}
                  filterInactive={filterInactive}
                  handleSetFilterInactive={handleSetFilterInactive}
                  canAddCollateral={true}
                  navigate={() => {}}
                  application={application}
                  refetch={() => {}}
                  setAction={setAction}
                  setShowModal={setShowModal}
                  setPaymentId={() => {}}
                />
              </div>
            ),
          },
          { label: 'User view', value: 'user', content: <UserDetails userId={application?.pestoUser?.id} /> },
          {
            label: 'Shipping view',
            value: 'shipping',
            content: <ShippingDetailsContent application={application} />,
          },
          {
            label: 'Admin Action Records',
            value: 'adminActionRecords',
            amount: application?.adminActionRecords?.length,
            content: <AdminActionRecordsContent application={application} />,
          },
          {
            label: 'Credit Check',
            value: 'creditCheck',
            amount: application?.creditChecks?.length,
            content: <CreditCheckContent title="Credit Checks" checks={application?.creditChecks} />,
          },
          {
            label: 'Doc Check',
            value: 'docCheck',
            amount: application?.docChecks?.length,
            content: (
              <DocCheckContent checks={application?.docChecks} title="Doc Checks" onButtonClick={handleButtonClick} />
            ),
          },
        ]}
        loading={loading}
      />

      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        title={getModalTitle()}
        onConfirm={() => handleModalConfirm(refetch)}
        loading={actionLoading}
      >
        <div>{modalContent()}</div>
      </Modal>
    </div>
  );
};
