/**
 * Applies a specified color scheme by updating CSS variables on the :root element.
 * @param schemeName - The name of the color scheme to apply.
 */

export type ColorScheme = {
  [key: string]: string;
};

export function applyColorScheme(schemeName: string, colorSchemes: Record<string, ColorScheme>): void {
  const scheme = colorSchemes[schemeName];

  if (!scheme) {
    console.warn(`Color scheme "${schemeName}" does not exist.`);
    return;
  }

  const root = document.documentElement;

  // Iterate over each CSS variable and update its value
  Object.entries(scheme).forEach(([variable, value]) => {
    root.style.setProperty(variable, `${value}`);
  });
}

/**
 * Retrieves the list of available color schemes.
 * @returns An array of color scheme names.
 */
export function getAvailableColorSchemes(colorSchemes: Record<string, ColorScheme>): string[] {
  return Object.keys(colorSchemes);
}
