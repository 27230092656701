import { Card, Typography } from '@pesto/ui';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@pesto/ui/components/Accordion';
import { DataList } from '@pesto/ui/components/DataList';
import { humanize } from '@pesto/utils';
import type { FC } from 'react';

interface CheckContentProps {
  title: string;
  checks?: any;
}

interface CreditCheckType {
  decision: string;
  type: string;
}

export const CreditCheckContent: FC<CheckContentProps> = props => {
  const { title, checks } = props;

  return (
    <div className={'space-y-4'}>
      <Typography variant="headerMedium">{`${title}s list`}</Typography>
      <div className="grid grid-cols-2 gap-6">
        {checks.map((item: CreditCheckType, index: number) => (
          <Accordion type="single" collapsible key={index}>
            <Card>
              <AccordionItem value={`item-${index}`} className="px-4 py-4">
                <AccordionTrigger>
                  <Typography variant={'body'} className="text-left">
                    {title} #{index}: {humanize(item?.decision ?? '')} | {humanize(item?.type ?? '')}
                  </Typography>
                </AccordionTrigger>
                {item && (
                  <AccordionContent>
                    <DataList root={item} spaced />
                  </AccordionContent>
                )}
              </AccordionItem>
            </Card>
          </Accordion>
        ))}
      </div>
    </div>
  );
};
