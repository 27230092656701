import axios from 'axios';
import { useCallback, useState } from 'react';

import type { DropFile } from '../ImageUploader';

function splitCamelCase(s: string, delimiter = ' ') {
  return s.split(/(?=[A-Z])/).join(delimiter);
}

interface Uploading {
  imgId: string;
  isLoading: boolean;
}

export default function useImageUploader(mutation: any) {
  const [isUploading, setUploading] = useState<Uploading>({
    imgId: '',
    isLoading: false,
  });

  const [generateAssetUploadUrlMutation] = mutation();

  const createUploadForm = (uploadMetaData: any, image: DropFile, fileType: string): FormData => {
    const form = new FormData();
    Object.entries(uploadMetaData)
      .filter(([key, value]) => key !== 'url' && key !== '__typename' && value)
      .forEach(([key]) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        form.append(splitCamelCase(key, '-').toLowerCase(), uploadMetaData[key]);
      });
    // to see what is in the form data, uncomment the line below
    // console.log('form', Object.fromEntries(form));
    form.append('acl', 'private');
    form.append('x-amz-meta-userid', 'anonymous');
    form.append('Content-Type', `${fileType}`);
    form.append('file', image);

    return form;
  };

  const uploadImage = useCallback(
    async (image: DropFile) => {
      try {
        setUploading({
          imgId: image.imgId || '',
          isLoading: true,
        });
        // 1. get upload url and params
        const result = await generateAssetUploadUrlMutation();
        const uploadMetaData = result?.data?.generateAssetUploadUrl;

        if (uploadMetaData) {
          // 2. get proper image uri depending on platform

          // 3. set form data
          const form = createUploadForm(uploadMetaData, image, image.type);

          // 4 create post and upload image
          if (uploadMetaData?.url) {
            const uploadResult = await axios({
              method: 'post',
              url: uploadMetaData?.url,
              data: form,
              headers: { 'Content-Type': 'multipart/form-data' },
            });

            if (!uploadResult.status) {
              return Promise.reject(uploadResult.statusText);
            }
            return uploadMetaData?.key;
          }
          return Promise.reject();
        } else {
          return Promise.reject(result?.errors?.[0] ? result?.errors?.[0] : 'Error generating upload url');
        }
      } catch (e) {
        return Promise.reject(e);
      } finally {
        setUploading({
          imgId: image.imgId || '',
          isLoading: false,
        });
      }
    },
    [generateAssetUploadUrlMutation],
  );

  return { uploadImage, isUploading };
}
