import type { ColorScheme } from '@pesto/utils/src/style/colorSchemeHelper';
import { applyColorScheme, getAvailableColorSchemes } from '@pesto/utils/src/style/colorSchemeHelper';
import type { ReactNode } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';

export type Theme = 'dark' | 'light' | 'system';
export type ColorSchemeValue = string;
export type ThemeProviderProps = {
  children: ReactNode;
  defaultTheme?: Theme;
  defaultColorScheme?: ColorSchemeValue;
  storageKey?: string;
  colorSchemeStorageKey?: string;
  colorSchemes?: Record<string, ColorScheme>;
};

type ThemeProviderState = {
  theme: Theme;
  setTheme: (theme: Theme) => void;
  colorScheme: ColorSchemeValue;
  setColorScheme: (scheme: ColorSchemeValue) => void;
  availableColorSchemes: string[];
};

const initialState: ThemeProviderState = {
  theme: 'system',
  setTheme: () => null,
  colorScheme: 'default',
  setColorScheme: () => null,
  availableColorSchemes: [],
};

const ThemeProviderContext = createContext<ThemeProviderState>(initialState);

export function ThemeProvider({
  children,
  defaultTheme = 'system',
  defaultColorScheme = 'default',
  storageKey = 'ui-theme',
  colorSchemeStorageKey = 'ui-color-scheme',
  colorSchemes = {},
  ...props
}: ThemeProviderProps) {
  const [theme, setTheme] = useState<Theme>(() => (localStorage.getItem(storageKey) as Theme) || defaultTheme);
  const [colorScheme, setColorSchemeState] = useState<ColorSchemeValue>(() => {
    const storedScheme = localStorage.getItem(colorSchemeStorageKey) as ColorSchemeValue;
    return storedScheme || defaultColorScheme;
  });

  const availableColorSchemes = getAvailableColorSchemes(colorSchemes);

  useEffect(() => {
    const root = window.document.documentElement;

    root.classList.remove('light', 'dark');

    if (theme === 'system') {
      const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';

      root.classList.add(systemTheme);
      return;
    }

    root.classList.add(theme);
  }, [theme]);

  useEffect(() => {
    applyColorScheme(colorScheme, colorSchemes);

    localStorage.setItem(colorSchemeStorageKey, colorScheme);
  }, [colorScheme, colorSchemeStorageKey, colorSchemes]);

  const value: ThemeProviderState = {
    theme,
    setTheme: (theme: Theme) => {
      localStorage.setItem(storageKey, theme);
      setTheme(theme);
    },
    colorScheme,
    setColorScheme: (scheme: ColorSchemeValue) => {
      localStorage.setItem(colorSchemeStorageKey, scheme);
      applyColorScheme(scheme, colorSchemes);
      setColorSchemeState(scheme);
    },
    availableColorSchemes,
  };

  return (
    <ThemeProviderContext.Provider {...props} value={value}>
      {children}
    </ThemeProviderContext.Provider>
  );
}

export const useTheme = () => {
  const context = useContext(ThemeProviderContext);

  if (context === undefined) throw new Error('useTheme must be used within a ThemeProvider');

  return context;
};
