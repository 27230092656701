import type { Config } from '@pesto/application-config';
import type { ReactNode } from 'react';

import { Toaster } from '../components/Toaster';

import { ConfigProvider } from './ConfigProvider';
import type { ThemeProviderProps } from './ThemeProvider';
import { ThemeProvider } from './ThemeProvider';

type ExtendedThemeProviderProps = Omit<ThemeProviderProps, 'children'>;

interface UiLibProviderProps {
  children: ReactNode;
  config?: Config;
  themeProviderProps?: ExtendedThemeProviderProps;
}

export const UiLibProvider = (props: UiLibProviderProps) => {
  const { children, config, themeProviderProps } = props;
  return (
    <ConfigProvider defaultConfig={config}>
      <ThemeProvider storageKey="ui-theme" colorSchemeStorageKey="ui-color-scheme" {...themeProviderProps}>
        <Toaster />
        {children}
      </ThemeProvider>
    </ConfigProvider>
  );
};
