import { CheckIcon } from '@heroicons/react/20/solid';
import type { ActionButton } from '@pesto/ui/features/CreditApplication/components/OverviewDetails';
import { CrossCircledIcon } from '@radix-ui/react-icons';

import { CreditApplicationStatus } from '../../../__generated__/graphql/api';

import { ApplicationActions } from './useApplicationModal';

export const useCreditApplicationDetails = () => {
  const mainActionButtons: Record<string, ActionButton> = {
    close: {
      label: 'Close application',
      action: ApplicationActions.CLOSE_APPLICATION,
      variant: 'outline',
      color: 'destructive',
      icon: CrossCircledIcon,
      sort: 1,
    },
    sendOffer: {
      label: 'Send Offer',
      action: ApplicationActions.SEND,
      variant: 'primary',
      icon: CheckIcon,
      sort: 2,
    },
    approve: {
      label: 'Approve application',
      action: ApplicationActions.APPROVE,
      variant: 'primary',
      icon: CheckIcon,
      sort: 3,
    },
  };

  const mainActionButtonsRender = (status: CreditApplicationStatus | null | undefined) => {
    if (!status) return [];
    const displayMainActionButtons = {
      [CreditApplicationStatus.ManualReview]: [mainActionButtons.approve],
    };

    const defaultButtons = [mainActionButtons.close, mainActionButtons.sendOffer];
    const buttons = displayMainActionButtons[status as keyof typeof displayMainActionButtons];
    return defaultButtons?.concat(buttons ?? []).sort((a, b) => a.sort - b.sort);
  };

  const applicationActionButtons = [
    {
      label: 'Force Doc Check',
      action: ApplicationActions.FORCE_DOC_CHECK,
    },
    {
      label: 'Force Payment Check',
      action: ApplicationActions.FORCE_PAYMENT_CHECK,
    },
    {
      label: 'Run Soft Credit Check',
      action: ApplicationActions.RUN_SOFT_CREDIT_CHECK,
    },
    {
      label: 'Run Hard Credit Check (Maybe Card Order)',
      action: ApplicationActions.RUN_HARD_CREDIT_CHECK_MAYBE_CARD_ORDER,
    },
  ];

  return {
    mainActionButtonsRender,
    applicationActionButtons,
  };
};
