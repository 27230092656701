import { useParams } from 'react-router-dom';

import { AddItem } from './components/AddItem';

export const AddFinalAppraisal = () => {
  const params = useParams();
  const existingItemId = params?.itemId;
  const creditApplicationId = params?.id ?? '';

  return <AddItem title={'Add Final Appraisal'} creditApplicationId={creditApplicationId} itemId={existingItemId} />;
};
