import type { ColorScheme } from '@pesto/utils/src/style/colorSchemeHelper';

export enum ColorSchemeValue {
  DEFAULT = 'default',
  HOMELIGHT = 'homeLight',
  PINK = 'pink',
  GREEN = 'green',
  ORANGE = 'orange',
}

export const colorSchemes: Record<string, ColorScheme> = {
  default: {
    '--primary': '273, 98%, 60%',
    '--accent': '273, 98%, 60%',
    '--accent-foreground': '240, 5.9%, 10%',
    // ... other variables
  },
  homeLight: {
    '--primary': '200 80% 50%',
    '--accent': '180 70% 60%',
  },
  pink: {
    '--primary': '330 80% 50%',
    '--accent': '310 70% 60%',
  },
  green: {
    '--primary': '150 80% 50%',
    '--accent': '130 70% 60%',
  },
  orange: {
    '--primary': '30 80% 50%',
    '--accent': '20 70% 60%',
  },
};
