import { zodResolver } from '@hookform/resolvers/zod';
import type { DropFile } from '@pesto/features';
import { ImageUploader } from '@pesto/features';
import { BackButton } from '@pesto/ui/components/BackButton';
import { Button } from '@pesto/ui/components/Button';
import { TextAreaField } from '@pesto/ui/components/Forms/TextAreaField';
import { TextField } from '@pesto/ui/components/Forms/TextField';
import { LoadingSpinner } from '@pesto/ui/components/LoadingSpinner';
import { Typography } from '@pesto/ui/components/Typography';
import { cleanFilesArray } from '@pesto/utils';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import type { OtherInput } from '../../../__generated__/graphql/api';
import { useAddItemMutation, useGenerateAssetUploadUrlMutation } from '../../../__generated__/graphql/api';
import { MainWrapper } from '../../../components/MainWrapper';

import { CurrencyFormatField } from './CurrencyFormatField';

interface AddItemProps {
  title: string;
  creditApplicationId: string;
  itemId?: string;
}

type AddItemFormValues = {
  creditApplicationId: string;
  itemId?: string;
  valuation: number;
  otherInput: OtherInput;
  photoLinks: DropFile[];
};

const addItemSchema = z.object({
  creditApplicationId: z.string().nonempty(),
  itemId: z.string().optional(),
  valuation: z
    .number()
    .min(1, 'Appraisal value must be greater than 0')
    .refine(val => val !== undefined, { message: 'Appraisal value is required' }),
  otherInput: z.object({
    otherAssetType: z.string().nonempty('Item name is required'),
    description: z
      .string()
      .nonempty('Item description is required')
      .max(240, 'Description cannot be more than 240 characters'),
  }),

  photoLinks: z.array(z.object({})).min(1, 'At least one document is required'),
});
export const AddItem = (props: AddItemProps) => {
  const { title = 'Add Item', creditApplicationId, itemId } = props;
  const existingItemId = itemId;

  const {
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<AddItemFormValues>({
    mode: 'onChange',
    resolver: zodResolver(addItemSchema),
    defaultValues: {
      creditApplicationId: creditApplicationId,
      itemId: existingItemId,
      valuation: 0,
      otherInput: {
        otherAssetType: '',
        description: '',
      },
      photoLinks: [],
    },
  });

  const formValues = {
    creditApplicationId: watch('creditApplicationId'),
    itemId: watch('itemId'),
    valuation: watch('valuation'),
    otherInput: watch('otherInput'),
    photoLinks: watch('photoLinks'),
  };

  const [isLoadingImages, setIsLoadingImages] = useState(false);
  const imagesPayload = cleanFilesArray(formValues?.photoLinks.map(photo => photo.responseKey));
  const [addItemMutation, { loading }] = useAddItemMutation();

  const disabledButton = loading || isLoadingImages;

  const navigate = useNavigate();
  const handleAddPhotoLink = useCallback(
    (value: DropFile[]) => {
      setValue('photoLinks', value);
    },
    [setValue],
  );
  const handleCancel = () => {
    navigate(-1);
  };
  const handleSave = async () => {
    await addItemMutation({
      variables: {
        creditApplicationId: creditApplicationId,
        itemId: existingItemId,
        valuation: formValues.valuation,
        otherInput: formValues.otherInput,
        photoLinks: imagesPayload ?? [],
      },
    });
    navigate(-1);
  };

  return (
    <div>
      <MainWrapper>
        <form className="flex flex-col gap-4" onSubmit={handleSubmit(handleSave)}>
          <BackButton onClick={handleCancel} />
          <Typography variant={'headerMedium'}>{title}</Typography>
          <div className={'flex flex-col gap-4 text-left'}>
            <TextField
              onChange={(e: any) => {
                setValue('otherInput.otherAssetType', e.target.value);
              }}
              type="text"
              defaultValue={formValues.otherInput?.otherAssetType}
              label="Item name"
              placeholder={'What is it?'}
              required={true}
            />
            <TextAreaField
              onChange={(e: any) => {
                setValue('otherInput.description', e.target.value);
              }}
              defaultValue={formValues.otherInput?.description}
              type="text"
              label="Item description"
              placeholder="What do you know about it?"
              required={true}
            />
            <CurrencyFormatField
              label={'Appraisal Value'}
              required
              error={!!errors.valuation}
              helperText={errors.valuation?.message}
              onChange={(e: any) => {
                setValue('valuation', e.target.value);
              }}
              value={formValues.valuation}
            />
            <div>
              <ImageUploader
                mutation={useGenerateAssetUploadUrlMutation}
                addPhotoLink={handleAddPhotoLink}
                isLoaded={setIsLoadingImages}
                required
                label={'Upload photos of your item'}
              />
              {errors.photoLinks && (
                <Typography variant={'body'} className={'text-danger'}>
                  {errors.photoLinks?.message}
                </Typography>
              )}
            </div>
          </div>
          <div className="pt-5">
            <div className="flex justify-end gap-2">
              <Button type="button" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                type="submit"
                className={'flex gap-2'}
                disabled={disabledButton}
                onClick={handleSubmit(handleSave)}
              >
                {loading && <LoadingSpinner />} Save
              </Button>
            </div>
          </div>
        </form>
      </MainWrapper>
    </div>
  );
};
